import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ContentfulService } from '../../services/contentful.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.css']
})
export class ArticleDetailsComponent implements OnInit {

  articleRaw: Observable<any>;
  articleHtml: string;
  locationValue = 'test';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private contentfulService: ContentfulService
  ) { }

  ngOnInit() {
    const articleId = this.route.snapshot.paramMap.get('id');

    this.articleRaw = this.contentfulService.getArticle(articleId);
    // this.location.go(this.locationValue);    
    // this.contentfulService.logArticle(articleId);
    
  }

  goToList() {
    this.router.navigate(['/courses']);
  }

}
