<mat-card class="card" [style.backgroundColor]="'#000c1d'">
<h1>Guides</h1>
</mat-card>
      <div *ngIf="(articles$ | async) as articles">
        <div *ngFor="let article of articles.slice()"><!--Reverses array order to show newest to oldest because array is in oldest to newest order -->
          <BR>


          <mat-card class="card" [style.backgroundColor]="'#223344'">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
              <div class="image-block">
                <img (click)="goToArticleDetailsPage(article.sys.id)" src="{{article.fields.image.fields.file.url}}">
              </div>

              <div>
                <mat-card-header>
                  <mat-card-title class="title" (click)="goToArticleDetailsPage(article.sys.id)">
                    {{article.fields.title}}</mat-card-title>
                </mat-card-header>

                <mat-card-content class="content">
                  {{article.fields.description}}
                  <BR>
                  <button mat-flat-button  color="accent" (click)="goToArticleDetailsPage(article.sys.id, article.fields.title)" class="btn btn-info">Read
                      More</button>
                </mat-card-content>
              </div>

            </div>
          </mat-card>
          <BR>

        </div>
      </div>