import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Lineup } from '../classes/lineup';
import { MatTableDataSource, MatIconRegistry } from '@angular/material';  
import {DomSanitizer} from '@angular/platform-browser';
import { Player } from '../../app/classes/player';

@Component({
  selector: 'app-lineup',
  templateUrl: './lineup.component.html',
  styleUrls: ['./lineup.component.css']
})
export class LineupComponent implements OnInit {

  @Input() lineupSource: MatTableDataSource<Lineup>;
  @Input() useCustomFppg: boolean;

  @Output() childRemovePlayerLineup = new EventEmitter();
  @Output() childClearLineup = new EventEmitter();

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'x-button',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/x.svg'));
   }

  ngOnInit() {
  }
  
  displayedColumns: string[] = ['player', 'team', 'position', 'fppg', 'remove'];
  
  
  getDisplayedColumns() {
    //This is a terrible way to do this. This will run 7 times, one for each column I believe. 
    if (this.useCustomFppg) {
      var index = this.displayedColumns.indexOf('fppg')
      this.displayedColumns[index] = 'customfppg';
      } else {
      var index = this.displayedColumns.indexOf('customfppg')
      this.displayedColumns[index] = 'fppg';
    }

    return this.displayedColumns;
  }

  
  removePlayer(player: Player) {
    this.childRemovePlayerLineup.emit(player);
  }


  clearLineup() {
    this.childClearLineup.emit();
  }

}
