import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
    selector: 'custom-projection-help',
    templateUrl: 'custom-projection-help.component.html',
    styleUrls: ['./custom-projection-help.component.css'],
  })
  export class CustomProjectionHelpComponent {
  
    constructor(public dialogRef: MatDialogRef<CustomProjectionHelpComponent>)  {}

    onNoClick(): void {
      this.dialogRef.close();
    }
}
