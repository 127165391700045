import { Player, ExcludeState, LockState } from "./player";

export class OptimizeRequest {
    numberLineups: Number;
    useCustomFppg: Boolean;
    Players: Player[];

    constructor(numberLineups: Number, useCustomFppg: Boolean, Players: Player[])
    {
        this.numberLineups = numberLineups;
        this.useCustomFppg = useCustomFppg;
        this.Players = Players;
    }

    private ConvertExcludeState() {
        this.Players.forEach( player => {
            if(player.excludeState == ExcludeState.isExcluded) {
                player.isExcluded = true;
            } else {
                player.isExcluded = false;
            }
        });
    }

    private ConvertLockState() {
        this.Players.forEach( player => {
            if(player.lockState == LockState.isLocked) {
                player.isLocked = true;
            } else {
                player.isLocked = false;
            }
        });
    }

    public ConvertButtonStates () {
        this.ConvertExcludeState();
        this.ConvertLockState();
    }

}
