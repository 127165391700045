import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Player } from '../classes/player';
import { Contests } from 'app/classes/contests';


const PLAYER_URL = environment.getplayersUrl;
const CONTEST_URL = environment.getcontestsUrl;
const OPTIMIZE_URL = environment.optimizeUrl;


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  players: Player[];

  constructor(private http: HttpClient
    ) { }

  public postLineup (optimizeRequest): Observable<Response> {
      return this.http.post<Response>(`${OPTIMIZE_URL}`, optimizeRequest)
      .pipe(
        catchError(this.handleError)
      );
    }
  
  public getContests (): Observable<Contests> {
            
      return this.http.get<Contests>(`${CONTEST_URL}`
      ) 
      .pipe(
        catchError(this.handleError)
      );
    }
  
  public testGetBlankContests (): Observable<Contests> {
      var contests = new Observable<Contests>();    
      return contests;
    }


  public getPlayers (groupId = 0): Observable<Player[]> {
    return this.http.get<Player[]>(`${PLAYER_URL}` + groupId.toString()
    ) 
    .pipe(
      catchError(this.handleError)
      );
    }

    private handleError(error: HttpErrorResponse) {    
      if (error.status == 0) {
        return throwError("Unable to retrieve players. Player salaries not available yet.");
      } else {
        return throwError(error.error);
      }
    }

}