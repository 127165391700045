// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  optimizeUrl: 'https://mxbm9qbh7f.execute-api.us-east-2.amazonaws.com/default/dashoptimize-dev-optimize',
  getcontestsUrl: 'https://kx0ci2oq35.execute-api.us-east-2.amazonaws.com/default/true-dev-fivepointstrike-getcontests-dynamo',
  getplayersUrl: 'https://8zh4lzplqi.execute-api.us-east-2.amazonaws.com/default/true-dev-fivepointstrike-getplayers-dynamo?groupId=',
  contentful: {
    spaceId: '7k67jxq4ihvf',
    token: 'aDNJOwV-5HLPQ3Or0EBwrvJlZJYo35uynORYnNc4r1w'
  }
};

//Former AWS account under SeanDSpam
// export const environment = {
//   production: false,
//   optimizeUrl: 'https://9crh9tm73i.execute-api.us-east-2.amazonaws.com/default/dashoptimize-dev-optimize',
//   getcontestsUrl: 'https://bggrf33ms9.execute-api.us-east-2.amazonaws.com/default/dashcontests-dev-getcontests',
//   getplayersUrl: 'https://qelkbkd8u3.execute-api.us-east-2.amazonaws.com/default/dashplayers-dev-getplayers?groupId=',
//   contentful: {
//     spaceId: '7k67jxq4ihvf',
//     token: 'aDNJOwV-5HLPQ3Or0EBwrvJlZJYo35uynORYnNc4r1w'
//   }
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
