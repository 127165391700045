<mat-card class="card" [style.backgroundColor]="'#000c1d'">
  <div class="optimize-banner-top" fxShow="true"  fxHide.lt-md><iframe scrolling='no' frameBorder='0'
      style='padding:0px; margin:0px; border:0px;border-style:none;border-style:none;' width='970' height='90'
      src="https://wlmonkeyknifefight.adsrv.eacdn.com/I.ashx?btag=a_1396b_74c_&affid=1215&siteid=1396&adid=74&c="></iframe>
  </div>
  <div class="optimize-banner-top" fxShow="true" fxLayoutAlign="center center" fxHide.gt-sm><iframe scrolling='no' frameBorder='0'
      style='padding:0px; margin:0px; border:0px;border-style:none;border-style:none;' width='300' height='250'
      src="https://wlmonkeyknifefight.adsrv.eacdn.com/I.ashx?btag=a_1396b_78c_&affid=1215&siteid=1396&adid=78&c="></iframe>
  </div>
  <div *ngIf="(articleRaw | async) as art">
    <h1>{{art.fields.title}}</h1>

    <div [innerHTML]="art.fields.article | mdToHtml"></div>
    {{articleHtml}}

    <p class="tags">Tags: {{art.fields.tags}}</p>
    <BR><BR><BR><BR>
  </div>

</mat-card>