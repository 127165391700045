export class Player {
   groupId: number;
   playerid: number;
   player: string = '';
   position: string = '';
   salary: number;
   team: string = '';
   fixture: string = '';
   fppg: number;
   customfppg: number;
   inLineup: boolean;
   isLocked: boolean;
   lockState: LockState;
   isExcluded: boolean;
   excludeState: ExcludeState;

   constructor() {
   }

}

export enum LockState {
   canLock = 'can-lock',
   isLocked = 'is-locked',
   cantLock = 'cant-lock'
}

export enum ExcludeState {
   canExclude = 'can-exclude',
   isExcluded = 'is-excluded',
   cantExclude = 'cant-exclude'
}
