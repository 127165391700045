import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { Player } from 'app/classes/player';
import { SelectionChecklist } from './../classes/SelectionChecklist';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }

  public importCustomProjectionTemplate(file: FileList) {
    
    if(file && file.length > 0) {
      let f : File = file[0]; 
      let reader: FileReader = new FileReader();
      let lines: any;
      let outList: Player[];

      console.log("first  "+ typeof(outList));

      reader.readAsText(f);
      reader.onload = (e) => {
        let csv: string = reader.result;
        lines = csv.split('\n');

        lines.forEach(line => {
          const cols: string[] = line.split(';');

          cols.forEach( entry => {
            var csvString = entry.split(',');
            if(csvString[0].length > 0 && csvString[0] != 'id') {
              let inputPlayer = new Player();
              inputPlayer.playerid=Number(csvString[0]);
              inputPlayer.customfppg=Number(csvString[6]);
              // input[csvString[0]]= csvString[6];
              outList.push(inputPlayer)
              // outList[csvString[0].toString()] = csvString[6];
              // outList.set(Number(csvString[0]), Number(csvString[6]));
            }
          });
        });
      }
      console.log(typeof(outList));
      return outList;
    }  
  }

  public exportCustomProjectionTemplate(playerList: Player[]): void {

    let formattedLineup = this.createTemplateExport(playerList);
    const fileName = "custom-projection-import-template" + new Date().getTime() + '.csv';

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedLineup);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, fileName);
  }

  private createTemplateExport(playerList: Player[]) {

    let trimmedPlayerList: Array<any> = [];

    playerList.forEach(player => {
      let trimmedPlayer: any = {
        'id': player.playerid,
        'name': player.player,
        'position': player.position,
        'salary': player.salary,
        'team': player.team,
        'fixture': player.fixture,
        'projected_points': 0
      };
      
      trimmedPlayerList.push(trimmedPlayer);
    });

    return trimmedPlayerList;
  }



  public exportLineupAsCsvFile(lineup: SelectionChecklist): void {

    let formattedLineup = this.createDraftKingsArray(lineup);
    const fileName = "draftkings-lineup-export-" + new Date().getTime() + '.csv';

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedLineup);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    XLSX.writeFile(workbook, fileName);
  }

  private createDraftKingsArray(lineup: SelectionChecklist) {

    let positionDict: any = [{
      'CPT': lineup.positions['CPT'][0].playerid,
      'TOP': lineup.positions['TOP'][0].playerid,
      'JNG': lineup.positions['JNG'][0].playerid,
      'MID': lineup.positions['MID'][0].playerid,
      'ADC': lineup.positions['ADC'][0].playerid,
      'SUP': lineup.positions['SUP'][0].playerid,
      'TEAM': lineup.positions['TEAM'][0].playerid
    }];
   
    return positionDict;
  }

}
