import { Player } from "./player";

export class SelectionChecklist {
    positions: {[posName: string]: Player[]} = {};
    salaryTotal: number;
    fantasyPointsTotal: number;
    teamCount: {};
    fixtureCount: {};
 
    constructor( 
    ) {
        this.positions['CPT'] = [];
        this.positions['TOP'] = [];
        this.positions['JNG'] = [];
        this.positions['MID'] = [];
        this.positions['ADC'] = [];
        this.positions['SUP'] = [];
        this.positions['TEAM'] = [];

        this.salaryTotal = 0;
        this.fantasyPointsTotal = 0;
        this.teamCount = {};
        this.fixtureCount = {};
    }
 }