import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort, MatTableDataSource, MatTable, MatPaginator, MatDialog, MatDialogRef } from '@angular/material';  
import { ApiService } from '../../app/services/api.service';
import { Player, LockState, ExcludeState} from '../../app/classes/player';
import { Contest } from '../../app/classes/contest';
import { OptimizeRequest } from '../../app/classes/optimize-request';
import { SelectionChecklist } from './../classes/SelectionChecklist';
import { ExcelService } from './../services/excel.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { TourService } from 'ngx-tour-md-menu';
import { CustomProjectionHelpComponent } from './custom-projection-help/custom-projection-help.component';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'lol-optimizer',
  templateUrl: './lol-optimizer.component.html',
  styleUrls: ['./lol-optimizer.component.css'],
})


export class LolOptimizerComponent implements OnInit {
  private ngUnsubscribe = new Subject();
  objectKeys = Object.keys;
  displayedColumns: string[] = ['lockplayer', 'excludeplayer', 'player', 'team', 'position', 'salary', 'fixture', 'fppg'];

  contestList: any;
  isContests: boolean = true;

  playerSource = new MatTableDataSource<Player>();//All players as pulled in by the API

  playerLineupList: Player[];//Players currently in the lineup
  lineupSource = new MatTableDataSource;//The data source for the lineup table. Essentially a copy of playerLineupList but in MatTableDataSource format

  player: Player;
  selectionChecklist = new SelectionChecklist();//Core object for team eligibility and stats logic
  issueList: string[];//To be deprecated or changed so there is just visual feedback on the selectChecklist attribute violated
  teamExceed: Boolean;

  excludeZeroFppg: Boolean = false; 
  useCustomFppg: Boolean = false;

  


  constructor(
    private apiService: ApiService, 
    private excelService: ExcelService, 
    private toastr: ToastrService, 
    public tourService: TourService, 
    public dialog: MatDialog,
    private spinnerService: Ng4LoadingSpinnerService
    ) {   }
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('customFppgInput') customFppgInput: ElementRef;


  ngOnInit() {
    this.spinnerService.show();

    //When we initialize the page we will get contests first and then pick the first one we've grabbed to then fetch the player list for that league.
    //Allows us to easily display the league the user is currently looking at on page load without messing with NG lifecycle hooks.

    this.getContests();

    this.tourService.initialize([{
      anchorId: 'league',
      content: 'First, select your league. These are the official Riot Games professional LoL leagues. Warning: If you change leagues you will lose all work from your previous selection.',
    }, {
      anchorId: 'lock',
      content: 'Next, you can lock players. The optimizer will always include locked players in your lineup.',
    }, {
      anchorId: 'stats',
      stepId: 'stats',
      content: 'We\'ve just locked the top player in the list. Any time you lock a player your lineup stats are updated. It will also show which positions have been filled at the bottom.',
    }, {
      anchorId: 'exclude',
      content: 'Excluding players ensures they will never appear in your lineup. The optimizer will ignore them.',
    }, {
      anchorId: 'filter',
      content: 'The filter bar will allow you to filter on any criteria (player name, team, position, etc.)',
    }, {
      anchorId: 'fppg',
      content: 'By default, the optimizer uses the Draft Kings Average Points Per Game to value each player. But this can be very limiting.',
    }, {
      anchorId: 'customprojection',
      content: 'To get around this, you can use your own custom projections. This is really where the optimizer can be helpful.',
    }, {
      anchorId: 'togglecustom',
      stepId: 'togglecustom',
      content: 'First, toggle on the custom projection display.',
    }, {
      anchorId: 'entercustommanual',
      content: 'You can enter the custom projections in manually, but this may be very tedious.',
    }, {
      anchorId: 'importcustom',
      content: 'Instead, you can export the current list of players to a CSV, and input your custom projections that way.',
    }, {
      anchorId: 'excludezero',
      content: 'If you don\'t produce projections for every player, just leave their projection 0 and this toggle will exclude them for you.',
    }
    ], 
    {enableBackdrop: true}
    );


    this.tourService.stepShow$.pipe(filter(step => step.stepId === 'togglecustom')).subscribe(
      step => {
        if(!this.useCustomFppg) {
          this.toggleCustomFpgg();
        }
      }
    );
    // don't forget to unsubscribe somehow when the component is destroyed

    this.tourService.stepShow$.pipe(filter(step => step.stepId === 'stats')).subscribe(
      step => {
        if(!this.playerSource.data[0].isLocked) {
          this.playerLocked(this.playerSource.data[0], false)
        }
      }
    );

  }


  /*
  API CALLS
  */

  onClickOptimize() {
    //Reset the validation checks
    this.teamExceed = false;
    this.validateLineup();

    var optimizeRequest = new OptimizeRequest(1, this.useCustomFppg, this.playerSource.data);
    optimizeRequest.ConvertButtonStates();

    if (this.issueList.length == 0) {
      this.spinnerService.show();
      this.apiService.postLineup(optimizeRequest)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(lineupreturned => {

        this.playerSource = new MatTableDataSource(JSON.parse(lineupreturned.body.toString()));
        this.playerSource.sort = this.sort;
        this.playerSource.paginator = this.paginator;
  
        this.selectionChecklist = new SelectionChecklist();//Reset the position counter
        this.updatePlayerLineup();//Retally the playerlist, lineup, position count, salaries, FPPG, etc.
        
        //This will correctly set the exclude image but lock image will be set to unlocked for all now
        this.initializeIcons();
        
        //The playerLocked for loop handles locking the right players and the corresponding images
        this.playerSource.data.forEach(player => {
          if(player.inLineup == true) {
            this.playerLocked(player, true)
          }     
        });
        this.spinnerService.hide();
      },
      err => {
        this.spinnerService.hide();
        this.issueList.push(err);
        this.showNotification(err)
      });  
    }
  }


  getContests() {
    this.apiService.getContests()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(contests => {   
        if (contests[0]) {
          this.isContests = true;
        } else {
          this.isContests = false;
        }
        
        this.getPlayers(contests[0].groupId);
        contests[0].isSelected = true;
        this.contestList = contests;
      
        this.contestList.forEach(contest => {
          if (contest.league == 'LCS Late' || contest.league == 'LEC Late'  || contest.league == 'EU Masters') {
            contest.imagePath = 'assets/icons-small/' + contest.league.replace(/\s/g, "").toLowerCase() + '.png';
          
          } else if (contest.league == 'LPL-LCK' || contest.league == 'LPL-LCK Promos' || contest.league == 'LCK-LPL' || contest.league == 'LMS-LPL-LCK') {
            contest.imagePath = 'assets/icons-small/' + 'lpl-lck.png'
            
          } else if (contest.league == 'LCK Promos') {
            contest.imagePath = 'assets/icons-small/' + 'lck.png'

          } else if (contest.league == 'LEC-VCS') {
            contest.imagePath = 'assets/icons-small/' + 'lec.png'
          
          } else if (contest.league == 'Academy-LCS' || contest.league == 'NA Academy') {
            contest.imagePath = 'assets/icons-small/' + 'naacademy.png'

          } else if (contest.league == 'MSI' || contest.league == 'MSI1' || contest.league == 'MSI2' || contest.league == 'MSI3' || contest.league == 'MSI4') {
            contest.imagePath = 'assets/icons-small/' + 'msi.png'
          
          } else if (contest.league == 'Rift Rivals' || contest.league == 'RR NA-EU' || contest.league == 'RR LCK-LPL-LMS' || contest.league == 'Rift Rivals 1' || contest.league == 'Rift Rivals 2') {
            contest.imagePath = 'assets/icons/' + 'riftrivals.png'
          
          } else if (contest.league == 'LCS' || contest.league == 'LCS Playoffs' || contest.league == 'LCS Regionals') {
            contest.imagePath = 'assets/icons-small/' + 'lcs.png'
          
          } else if (contest.league == 'Worlds' || contest.league == 'Worlds 1' || contest.league == 'Worlds 2' || contest.league == 'Worlds 3' || contest.league == 'Worlds Late') {
            contest.imagePath = 'assets/icons/' + 'worlds.png'
          
          } else {
            contest.imagePath = 'assets/icons-small/' + contest.league.toLowerCase() + '.png';
          }
        })
    });
  }


  getPlayers(groupId?: number) {
    this.apiService.getPlayers(groupId)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(players => {
      this.playerSource = new MatTableDataSource(players);
      this.playerSource.sort = this.sort;
      this.playerSource.paginator = this.paginator;
      this.initializeButtonsAndStates();
      this.initializeIcons();
      this.initializeCustomFppg();
      // this.spinnerService.hide();
    },
    err => {
      this.spinnerService.hide();
      this.issueList = [];
      this.issueList.push(err);
      this.showNotification(err);
    });  
  }


  onClickSwitchLeague(contest: Contest) {
    contest.isSelected = true;
    this.getPlayers(contest.groupId);
    this.resetCustomFppgUpload();
    this.clearLineup();
    this.excludeZeroFppg = false;

    this.contestList.forEach(con => {
      if (con.groupId != contest.groupId) {
        con.isSelected = false;
      }
    });
  }

  
  onClickLineupExport() {
    this.excelService.exportLineupAsCsvFile(this.selectionChecklist);
  }


  /*
  * Initialize Things for First Load
  */

initializeButtonsAndStates() {
  this.playerSource.data.forEach(player => {
    player.lockState = LockState.canLock;
    player.excludeState = ExcludeState.canExclude;
  });
}


initializeIcons() {
  this.playerSource.data.forEach(player => {
    if(player.excludeState == ExcludeState.isExcluded || player.isExcluded == true) {
      this.updateLockState(player, LockState.cantLock);
      this.updateExcludeState(player, ExcludeState.isExcluded);
    
    } else {
      this.updateExcludeState(player, ExcludeState.canExclude);
      this.updateLockState(player, LockState.canLock);
    }
  });
}


initializeCustomFppg() {
  this.playerSource.data.forEach(player => {
    if(typeof player.customfppg === 'undefined') {
      player.customfppg = 0;
    }
  });
  this.spinnerService.hide();
}


  /*
  Add/Remove Players from lineup
  */

  playerLocked(player: Player, isFromOptimizer: Boolean) {//MAIN LOOP WHEN LOCKED IS SELECTED
    //Don't do any of this if the player's already been excluded or they can't be locked
    if (player.excludeState != ExcludeState.isExcluded || player.lockState == LockState.cantLock) {

      //If the player wasn't from the optimizer service then toggle it off/on. So the actual change to the player object happens here
      if (!isFromOptimizer && player.lockState != LockState.cantLock) {
        player.inLineup = (typeof player.inLineup === 'undefined') ? true : !player.inLineup;
      }   
      

      this.getPositionBoxColor(this.cptSplit(player.position));

      if (player.inLineup) //Turned true so it's an add
      {
        var addResult = this.tryAdd(player);
        if (addResult)
        {
          this.updateLockState(player, LockState.isLocked);
          this.updateLockButtonsAndStates(player);
          this.updatePlayerLineup();

        } else {
          console.log('Player Not Added:');
          console.log(player.player)
        }
      } else { //Turned false so it's a remove
        var removeResult = this.tryRemove(player); 
        if (removeResult)
        {
          this.updateLockState(player, LockState.canLock);
          this.updateLockButtonsAndStates(player);
          this.updatePlayerLineup();
         
        } else {
          console.log('Player Not Removed:');
          console.log(player.player);
          
        }
      }
    }
  }


  tryAdd(player) {//Attempt to add the player from the lineup count. If this fails it will not run updatePlayerLineup
    if (this.selectionChecklist.positions[this.cptSplit(player.position)].length == 0)
    {
      this.selectionChecklist.positions[this.cptSplit(player.position)].push(player);
      return true;
    } 

    return false;
  }


  tryRemove(player) {//Attempt to remove the player from the lineup count. If this fails it will not run updatePlayerLineup
    if (this.selectionChecklist.positions[this.cptSplit(player.position)].includes(player))
    {
      var indexpos = this.selectionChecklist.positions[this.cptSplit(player.position)].indexOf(player);
      this.selectionChecklist.positions[this.cptSplit(player.position)].splice(indexpos, 1);
      return true;
    }

    return false;
  }


  updatePlayerLineup() {//Recreate the mattabledatasource each time by iterating through object list on inLineup
    this.playerLineupList = [];

    this.playerSource.data.forEach(player => {
      if(player.inLineup === true)
      {
        this.playerLineupList.push(player)
      }
    });
    
    this.calculateLineupStats();
    this.lineupSource = new MatTableDataSource(this.playerLineupList);
  }


  updateLockButtonsAndStates(player: Player) {//This mostly handles button state for OTHER players and also handles the exclude as well.
    //LOCKING
    if(player.lockState == LockState.isLocked) {//If you're locking the player disable all other positions for that player's position. Update the player and the other players
      this.updateExcludeState(player, ExcludeState.cantExclude);
      
      this.playerSource.data.forEach(iterPlayer => {
        //Update all other players in that position
        if ( (this.cptSplit(iterPlayer.position) == this.cptSplit(player.position)) && (player.playerid != iterPlayer.playerid) ) {
          this.updateLockState(iterPlayer, LockState.cantLock);
        } 
        //Update the same player for the other position (i.e. original position or the CPT form) - you cannot have the same player twice
        if ( (iterPlayer.player == player.player) && (player.playerid != iterPlayer.playerid) ) {
          this.updateLockState(iterPlayer, LockState.cantLock);
        } 
      });
    
    //UNLOCKING
    } else { //If you're unlocking the player make them eligible to be locked and excluded and any other player that has not been excluded is now also eligible to be locked
      
      //Update the player being unlocked
      
      this.updateLockState(player, LockState.canLock);
      this.updateExcludeState(player, ExcludeState.canExclude);

      
      this.playerSource.data.forEach(iterPlayer => {
        //Update all other players in that position
        if (this.cptSplit(iterPlayer.position) == this.cptSplit(player.position))
        {
          if(iterPlayer.excludeState != ExcludeState.isExcluded ) 
          {
            this.updateLockState(iterPlayer, LockState.canLock);
          }
        } 

        //Update lock capability for CPT or vanilla position for the same player
        if ((iterPlayer.player == player.player) && (player.playerid != iterPlayer.playerid))
        {
          if(iterPlayer.excludeState != ExcludeState.isExcluded ) 
          {
            this.updateLockState(iterPlayer, LockState.canLock);
          }
        } 
      });
    }
  }

  /*
  * Excluding Players 
  */

  resetExcludes() {
    this.playerSource.data.forEach(player => {
        if(player.excludeState == ExcludeState.isExcluded) {
          this.playerExcluded(player);
        }
    });
  }


  playerExcluded(player: Player) {

    let isAlreadyLineup: boolean;

    if (player.lockState != LockState.isLocked) { //Don't do any of this if the player's already been locked
        if (typeof player.excludeState === 'undefined' || player.excludeState == ExcludeState.canExclude) {
          this.updateExcludeState(player, ExcludeState.isExcluded);
          this.updateLockState(player, LockState.cantLock);

        } else if (player.excludeState == ExcludeState.isExcluded) {
          this.updateExcludeState(player, ExcludeState.canExclude);

          //This sucks because this logic is from TryAdd() and now it needs to be changed here too
          //Only unlock the player if their position is avaiable. 
          if (this.selectionChecklist.positions[this.cptSplit(player.position)].length == 0) { 
            //Only unlock the player if their not already in the lineup as position or captain
            //Using a for instead of foreach so I can easily break the loop as soon as a duplicate is found
            for (let iterPlayer of this.playerSource.data) {
              if ( (iterPlayer.player == player.player) && (player.playerid != iterPlayer.playerid) && (iterPlayer.isLocked == true) ) {
                isAlreadyLineup = true;
                break;
              } else {
                isAlreadyLineup = false;
              }
            } 

            //When the for loop is over then evaluate that the result of the isAlreadyLineup check is
            //This SHOULD flip to true and exit the for as soon as it finds a duplicate
            if (!isAlreadyLineup) {
              this.updateLockState(player, LockState.canLock);
            } else { 
              return;
            }

          }
        } else if (player.excludeState == ExcludeState.cantExclude) {
          return;
        }
      }
    }

 
  toggleExcludeZeroFppg() {
    this.excludeZeroFppg = (typeof this.excludeZeroFppg === 'undefined') ? true : !this.excludeZeroFppg;
    
    if (this.excludeZeroFppg) {
      this.playerSource.data.forEach(player => {
        if(this.useCustomFppg) {
          if(player.customfppg == 0 && player.excludeState == ExcludeState.canExclude) {
            this.playerExcluded(player);
          }
        } else {
          if(player.fppg == 0 && player.excludeState == ExcludeState.canExclude) {
            this.playerExcluded(player);
          }
        }            
      });
    } else {
      if (this.useCustomFppg) {
        this.playerSource.data.forEach(player => {
          if(player.customfppg == 0 && player.excludeState == ExcludeState.isExcluded) {//Uhhh is this correct? Why is this here?
            this.playerExcluded(player);
          }     
        });
      } else {
        this.playerSource.data.forEach(player => {
          if(player.fppg == 0 && player.excludeState == ExcludeState.isExcluded) {//Uhhh is this correct? Why is this here?
            this.playerExcluded(player);
          }     
        });
      }
    }
  }


  /*
  * Calculate Lineup Stats and Lineup Validation
  */

  calculateLineupStats() {
    this.selectionChecklist.salaryTotal = 0;
    this.selectionChecklist.fantasyPointsTotal = 0;
    this.selectionChecklist.teamCount = {};
    this.selectionChecklist.fixtureCount = {};

    this.playerLineupList.forEach(player => {
        this.selectionChecklist.salaryTotal = player.salary + this.selectionChecklist.salaryTotal;
        this.selectionChecklist.salaryTotal = Math.round(this.selectionChecklist.salaryTotal * 100) / 100;

        if (this.cptSplit(player.position) != "TEAM") { //Selecting a team does not count towards 4 players on a team limit    
          this.selectionChecklist.teamCount[player.team] = (this.selectionChecklist.teamCount[player.team] || 0) + 1;
        }

        this.selectionChecklist.fixtureCount[player.fixture] = (this.selectionChecklist.fixtureCount[player.fixture] || 0) + 1;
        
        if (this.useCustomFppg)
        {
          this.selectionChecklist.fantasyPointsTotal = player.customfppg + this.selectionChecklist.fantasyPointsTotal;
        } else {
          this.selectionChecklist.fantasyPointsTotal = player.fppg + this.selectionChecklist.fantasyPointsTotal;
        }
        this.selectionChecklist.fantasyPointsTotal = Math.round(this.selectionChecklist.fantasyPointsTotal * 100) / 100;
    });
  }


  validateLineup() {
    this.issueList = [];//Reset the list because we have to validate again if changes are made.

    //Check if we exceeded team limit of 4
    for (let key in this.selectionChecklist.teamCount) {
      if(this.selectionChecklist.teamCount[key] > 4) {
        this.issueList.push("Cannot Optimize: Team Limit of 4 Exceeded. Too many players from: " + key + ".");
        this.teamExceed = true;
      }  
    }

    //Check if we "exceeded" the fixture limit of 7
    for (let key in this.selectionChecklist.fixtureCount) {
      if(this.selectionChecklist.fixtureCount[key] == 8)
      this.issueList.push("Cannot Optimize: Minimum Fixtures of 2 Not Met. Too many players from: " + key + ".");
    }

    //Check to see if Salary exceeds limit (This should probably be a calc to see if you can even optimize if you leave too little in salary)
    if(this.selectionChecklist.salaryTotal > 50000) {
      this.issueList.push("Cannot Optimize: Salary Exceeded.");
    }

    this.issueList.forEach(issue => {
      this.showNotification(issue);
    })

  }

  
  getPositionBoxColor(position) {//Color the boxes of the position count depending on if the max is hit
    if (this.selectionChecklist.positions[position].length == 0) {
      return '#a4b3b6';//#ffffff
    } else if (this.selectionChecklist.positions[position].length == 1) {
      return '#ffc107';//#6bec6d        
    }      
  }


  /*
  * Reset functions
  */


  resetCustomFppgUpload() {
    this.customFppgInput.nativeElement.value = "";
  }

  resetPlayerLocksAndInLineup() {
    this.playerSource.data.forEach(player => {
        player.inLineup = false;
        if (player.excludeState != ExcludeState.isExcluded) {
          this.updateLockState(player, LockState.canLock);
        }
    });
  }


  clearLineup(){
    this.lineupSource = new MatTableDataSource();//The display array for the table itself
    this.playerLineupList = [];//The array you fill with the table, probably should do away with this and reset the lineupSource on its own
    this.issueList = [];
    this.selectionChecklist = new SelectionChecklist();//Nuke all the objects driving the team stats calc

    this.initializeIcons();
    this.calculateLineupStats();//Then recalc - maybe it's faster to NOT do this and set everything to zero but then you're setting the values in multiple places. Possible performance improvement.
    this.resetPlayerLocksAndInLineup();//Any players locked need to be unlocked
  }


  resetCustomProjections() {
    this.playerSource.data.forEach(player => {
      player.customfppg = 0;
    });
    this.resetCustomFppgUpload();
  }


  /*
  Custom FPPG 
  */

  toggleCustomFpgg() {
    this.useCustomFppg = (typeof this.useCustomFppg === 'undefined') ? true : !this.useCustomFppg;
    this.excludeZeroFppg = false;
    this.resetExcludes();//Reset this so you can use it again with the custom or DK projection.
  }


  updateCustomFppg(customfppg: number, player: Player) {
    player.customfppg = customfppg;
  }


  onClickExportCustProjTemplate() {
    this.excelService.exportCustomProjectionTemplate(this.playerSource.data);
  }


  customTemplateUploadListener(file: FileList) {
    if(file && file.length > 0) {

      if(!this.useCustomFppg) {
        this.toggleCustomFpgg();
      }      

      let f : File = file[0]; 
      let reader: FileReader = new FileReader();
      let lines: any;

      let matchFound: boolean;
      let noMatchCount = 0;

      reader.readAsText(f);
      reader.onload = (e) => {
        let csv: string = reader.result;
        lines = csv.split('\n');


        lines.forEach(line => {
          const cols: string[] = line.split(';');

          cols.forEach( entry => {
              var csvString = entry.split(',');
              if(csvString[0].length != 0 && csvString[0] != 'id') {
                matchFound = this.updatePlayerFppg(Number(csvString[0]), Number(csvString[6]));
                
                if (!matchFound) {
                  noMatchCount++;
                }
            } 
          });
        });

        if (noMatchCount != 0) {
          let warningMessage = 'Warning: There were ' + noMatchCount.toString() + ' players not matched upon upload. Please confirm league and your template.';
          this.showNotification(warningMessage);
        } else {
          this.showNotification('Upload Successful. If you wish to reload your spreadsheet, please click Reset Custom Projection first.', NotificationType.success);
        }

      }
    }
  }


  updatePlayerFppg (id: number, fppg: number): boolean {
    let playerMatched: boolean;
    playerMatched = false;

    this.playerSource.data.forEach(player => {
      if (player.playerid === id) {
        player.customfppg = fppg;
        playerMatched = true;
      }
    })

    return playerMatched;
  }


  /*
  Miscellaneous
  */

  openDialog(): void {
    const dialogRef = this.dialog.open(CustomProjectionHelpComponent, {
      width: '800px'
    });
  }

  showNotification(message: string, notificationType: NotificationType = NotificationType.error) {
    if (notificationType == NotificationType.error) {
      this.toastr.error(message);
    } else if (notificationType == NotificationType.warning) {
      this.toastr.warning(message);
    } else if (notificationType == NotificationType.success) {
      this.toastr.success(message);
    }    
  }

  getDisplayedColumns() {
    //This is a terrible way to do this. This will run 7 times, one for each column I believe. 
    if (this.useCustomFppg) {
      this.displayedColumns.splice(this.displayedColumns.indexOf('fppg'), 1, 'customfppg');
    } else {
      this.displayedColumns.splice(this.displayedColumns.indexOf('customfppg'), 1, 'fppg');
    }
    return this.displayedColumns;
  }


  applyFilter(filterValue: string) {
    this.playerSource.filter = filterValue.trim().toLowerCase(); 
  }


  cptSplit(position) {//Format of CPT is CPT (POS) but we want to add to a dict that stores it as "CPT" so strip off the (POS) part
    return position.split(" ")[0];
  }


  /*
  Update State Helper Functions
  */


  public updateExcludeState(player: Player, state: ExcludeState) {
    if (state == ExcludeState.canExclude) {
      player.isExcluded = false;
      player.excludeState = ExcludeState.canExclude
    } else if (state == ExcludeState.isExcluded) {
      player.isExcluded = true;
      player.excludeState = ExcludeState.isExcluded
    } else if (state == ExcludeState.cantExclude) {
      player.isExcluded = false;
      player.excludeState = ExcludeState.cantExclude;
    }
  }

  public updateLockState(player: Player, state: LockState) {
    if (state == LockState.canLock) {
        player.isLocked = false;
        player.lockState = LockState.canLock;

    } else if (state == LockState.isLocked) {
        player.isLocked = true;
        player.lockState = LockState.isLocked;
    
    } else if (state == LockState.cantLock) {
        player.isLocked = false;
        player.lockState = LockState.cantLock;
    }
  }


  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}


export enum NotificationType {
  error,
  warning,
  success
}