<ng4-loading-spinner [loadingText]="'Loading... One Moment Please'"> </ng4-loading-spinner>
<div class="optimizer-banner-top" fxShow="true" fxHide.lt-md><iframe scrolling='no' frameBorder='0' style='padding:0px; margin:0px; border:0px;border-style:none;border-style:none;' width='970' height='90' src="https://wlmonkeyknifefight.adsrv.eacdn.com/I.ashx?btag=a_1396b_74c_&affid=1215&siteid=1396&adid=74&c=" ></iframe></div>
<table class="overall-table table-alt">
    <div fxLayout.lt-lg="column" class="container">
        <div class='left-box'><h1>League of Legends<BR>Lineup Optimizer</h1></div>
        <div class='middle-box'>
          <p>This tool is a lineup optimzer for daily fantasy sports contests on Draft Kings. If this is your first time using the lineup optimizer, please take a tour for a quick walkthrough.</p>
        </div>
        <div *ngIf="isContests" class='right-box tour'><button mat-flat-button  color="accent" (click)="tourService.start()">Tour the Optimizer</button></div>  
      </div>

  <tr *ngIf="!isContests">
      <h3>No Contests currently available. Please check back soon!</h3>
  </tr>

  <tr *ngIf="isContests">
    <td>
      <table>
          
        <!-- Overall table top row  -->
        <tr>
          <!-- Top right box for league/custom projections  -->
          <td>
            <table class="no-color-row">
              <th>Select League:</th>
              <th>Set Custom Projections (Optional): </th> 

              <!-- League and Custom Projection Row -->
              <tr>

                <!-- League  -->
                <td tourAnchor="league">
                  <div class="container league-wrap">
                    <div league-list *ngFor="let contest of contestList">
                      <img class="league-image" 
                        [style.border]="contest.isSelected ? '1px solid #ffc107' : 'none'"
                        [style.padding]="contest.isSelected ? '9px' : '10px'"
                        (click)="onClickSwitchLeague(contest)" src="{{contest.imagePath}}" />
                      <p class="league-name">{{contest.league}}</p>
                      <p class="league-date">{{contest.startTime | date: medium}}</p>
                    </div>
                  </div>
                </td>
                
                <!-- Custom Projections  -->
                <td tourAnchor="customprojection">
                  <table class="center no-color-row">
                      <tr>
                          <td tourAnchor="togglecustom">
                            <mat-slide-toggle [checked]="useCustomFppg" (change)="toggleCustomFpgg()">Use Custom FPPG Values
                            </mat-slide-toggle>
                          </td>
                          <td tourAnchor="excludezero">
                            <mat-slide-toggle [checked]="excludeZeroFppg" (change)="toggleExcludeZeroFppg()">Exclude 0 FPPG
                              Players
                            </mat-slide-toggle>
                          </td>
                      </tr>
                    <tr>
                      <td tourAnchor="importcustom"><button mat-flat-button color="accent"(click)="onClickExportCustProjTemplate()">Export Custom Projection
                          Template</button>
                      </td>
                      <td><button mat-flat-button color="accent" (click)="resetCustomProjections()">Reset Custom Projection</button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>Upload Custom Projection:</p>
                      </td>
                      <td><input #customFppgInput type="file" class="upload"
                          (change)="customTemplateUploadListener($event.target.files)"></td>
                    </tr>
                    <tr>
                      <td colspan="2" class="custom-proj-help">
                        <!-- <mat-icon matTooltip="Help with Custom Projections">help</mat-icon> -->
                        <P><a role="button" class="custom-proj-help-button" (click)="openDialog()">Help with Custom Projections</a></P></td>
                    </tr>
                  </table>
                </td>

              </tr>
              <!-- END League and Custom Projection Row -->

              <!-- Filter and Lineup Button Row  -->
              <tr>
                <td colspan="2">
                  <table class="filter-and-buttons-table">
                    <tr>
                      <td class="filter-field-cell">
                        <div class="filter-field filter-field-cell">
                          <mat-form-field tourAnchor="filter" appearance="outline" class="mat-form-field-filter">
                            <mat-label>Filter Players</mat-label>
                            <input matInput (keyup)="applyFilter($event.target.value)"
                              placeholder="Player, team, position, salary...">
                          </mat-form-field>
                        </div>
                      </td>
                      <td class="filter-buttons-cell">
                        <button mat-flat-button color="accent" class="filter-buttons" (click)="resetExcludes()">Reset Excludes</button>
                        <button mat-flat-button color="accent" class="filter-buttons" (click)="clearLineup()">Clear Lineup</button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <!--  END Filter and Lineup Button Row  -->

            </table>
          </td>
          <!-- END Top right box for league/custom projections  -->


          <!-- Lineup pane, right side  -->
          <td rowspan="2" class="lineup-pane">

            <div class="optimize-export-buttons">
              <button mat-flat-button color="accent" class="large-button" (click)="onClickOptimize()">Optimize
                  Lineup</button>
              <button mat-flat-button color="accent" class="large-button" (click)="onClickLineupExport()">Export
                  Lineup</button>
            </div>

            <div tourAnchor="stats"> <!-- Tour Stats -->
            <!-- Salary/Points/Teams -->
            <!-- Headers  -->
            
            <div class="team-stats-row">
              <div class="stats-sal-points salpointsteam-align">
                <span class="salpointsteam-header">Salary</span>
              </div>
              <div class="stats-sal-points salpointsteam-align">
                <span class="salpointsteam-header">Projected Points</span>
              </div>
              <div class="stats-sal-points teams-header">
                <span class="salpointsteam-header">Teams</span>
              </div>
            </div>

            <!-- Actual values  -->
            <div class="team-stats-row">
              <div class="stats-sal-points salpointsteam-align">
                <p class="sal-points-size">{{selectionChecklist.salaryTotal}}</p>
              </div>
              <div class="stats-sal-points salpointsteam-align">
                <p class="sal-points-size">{{selectionChecklist.fantasyPointsTotal}}</p>
              </div>
              <div class="stats-sal-points teams-header">
                <div class="positions-horizontal">
                  <div *ngFor="let key of objectKeys(selectionChecklist.teamCount)" class="stats-horizontal-list">
                    <span>
                      {{selectionChecklist.teamCount[key]}} <BR> {{key}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- END Salary/Points/Teams -->

            <!-- Positions bubbles  -->
            <div class="team-stats-row">
              <div class="positions-horizontal">
                <div *ngFor="let key of objectKeys(selectionChecklist.positions)">
                  <div [ngStyle]="{'background-color': getPositionBoxColor(key)}"
                    class="stats-horizontal-list positions-style">
                    {{key}}
                  </div>
                </div>
              </div>
            </div>
            <!-- END Positions bubbles  -->
          </div> <!-- End Tour Stats -->

            <!-- Lineup Component  -->
            <app-lineup (childClearLineup)="clearLineup()" (childRemovePlayerLineup)="playerLocked($event, false)"
              [lineupSource]="lineupSource" [useCustomFppg]="useCustomFppg"></app-lineup>
          
          </td>
          <!-- END Lineup pane, right side  -->  

        </tr>
        <!-- END Overall table top row  -->


        <!-- Player Table  -->
        <tr>
          <td>

      <table mat-table [dataSource]="playerSource" matSort class="player-table">
        <ng-container matColumnDef="lockplayer">
          <th mat-header-cell tourAnchor="lock" *matHeaderCellDef>Lock</th>
          <td mat-cell *matCellDef="let player">
            <button mat-icon-button [ngClass]="player.lockState" (click)="playerLocked(player, false)">
              <mat-icon>lock</mat-icon>
            </button>
          </td>
          </ng-container>


          <ng-container matColumnDef="excludeplayer">
            <th mat-header-cell tourAnchor="exclude" *matHeaderCellDef>Exclude</th>
            <td mat-cell *matCellDef="let player">
              <button mat-icon-button [ngClass]="player.excludeState" (click)="playerExcluded(player)">
                <mat-icon>cancel</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="player">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Player</th>
            <td mat-cell *matCellDef="let player"> {{player.player}} </td>
          </ng-container>

          <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
            <td mat-cell *matCellDef="let player"> {{player.team}} </td>
          </ng-container>

          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
            <td mat-cell *matCellDef="let player"> {{player.position}} </td>
          </ng-container>

          <ng-container matColumnDef="salary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Salary</th>
            <td mat-cell *matCellDef="let player"> {{player.salary}} </td>
          </ng-container>

          <ng-container matColumnDef="fixture">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fixture</th>
            <td mat-cell *matCellDef="let player"> {{player.fixture}} </td>
          </ng-container>

          <ng-container matColumnDef="fppg">
            <th mat-header-cell tourAnchor="fppg" *matHeaderCellDef mat-sort-header>FPPG</th>
            <td mat-cell *matCellDef="let player"> {{player.fppg}} </td>
          </ng-container>

          <ng-container matColumnDef="customfppg">
            <th mat-header-cell tourAnchor="entercustommanual" mat-header-cell-customfppg *matHeaderCellDef mat-sort-header>Custom</th>
            <td mat-cell-customfppg mat-cell *matCellDef="let player">
              <mat-form-field class="mat-form-field-customfppg">
                <input matInput name="customfppg" type="number" (keyup)="updateCustomFppg($event.target.value, player)"
                  [value]="player.customfppg">
              </mat-form-field>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>

      </table>
      <!-- END Player Table  -->

      <mat-paginator [pageSizeOptions]="[10, 200]" showFirstLastButtons></mat-paginator>

    </td>
  </tr>
</table>

<!-- <tour-step-template></tour-step-template> -->
<tour-step-template>
    <ng-template let-step="step">
      <mat-card (click)="$event.stopPropagation()">
        <mat-card-title>
          {{step?.title}}
        </mat-card-title>
        <mat-card-content>
          {{step?.content}}
        </mat-card-content>
        <mat-card-actions>
          <button mat-icon-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button mat-icon-button [disabled]="!tourService.hasNext(step)" (click)="tourService.next()">
            <mat-icon>chevron_right</mat-icon>
          </button>
          <button mat-button (click)="tourService.end()">{{step?.endBtnTitle}}</button>
        </mat-card-actions>
      </mat-card>
    </ng-template>
  </tour-step-template>