import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { environment } from '../../environments/environment';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ContentfulService {
  private client = createClient({
    space: environment.contentful.spaceId,
    accessToken: environment.contentful.token
  });

  constructor() { }

  // getArticles(query?: object): Promise<Entry<any>[]> {
  //   return this.client.getEntries(Object.assign({
  //     content_type: 'article'
  //   }, query))
  //     .then(res => res.items);
  // }
  
  getArticles() {
    const promise = this.client.getEntries();
    return from(promise).pipe(map(response => response.items))
  }

  logArticles() {
    this.client.getEntries()
    .then(entry => console.log(entry.items) )
  }

  logArticle(articleId) {
    this.client.getEntry(articleId)
    .then(entry => console.log(entry.fields) )
  }

  getArticle(articleId): Observable<Entry<any>> {
    const promise = this.client.getEntry(articleId);
    return from(promise).pipe(map(response => response))
  }


  // getArticle(articleId): Promise<Entry<any>> {
  //   return this.client.getEntries(Object.assign({
  //    content_type: 'article'
  //   }, {'sys.id': articleId}))
  //     .then(res => res.items[0]);
  // }

}