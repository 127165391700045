<hr>
<div class="container">
  <div class="test">
    <a class="footer-links" routerLink="/about-us" routerLinkActive="active">About Us</a>
    <a class="footer-links" routerLink="/terms" routerLinkActive="active">Privacy</a>
  </div>
  <div class="test">Five Point Strike is not endorsed by Riot Games and does not reflect the views or opinions of Riot
    Games or anyone officially involved in producing or managing League of Legends. League of Legends and Riot Games are
    trademarks or registered trademarks of Riot Games, Inc. League of Legends © Riot Games, Inc.
  </div>

</div>