<div class="content">
  <br>
    <mat-card class="card" [style.backgroundColor]="'#ffc107'">
        <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch"> -->
            <mat-card-header>
              <mat-card-title class="title">
                Try our Draft Kings Lineup Optimizer</mat-card-title>
            </mat-card-header>

            <mat-card-content class="content">
              Try our completely free Draft Kings lineup optimzer! Lock and exclude players, import custom points projections, and more. Feedback welcome!
              <BR>
              
            </mat-card-content>
            <button routerLink="/lol-optimizer" mat-flat-button color="'#223344'">Optimize Some Stuff!</button>
        <!-- </div> -->
      </mat-card>
      <app-article-list></app-article-list>

  </div>