import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'; 
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatSidenavModule,
  MatTooltipModule,
  MatRadioModule,
  MatDividerModule,
  MatDialogModule,
  MatListModule
} from '@angular/material';
import 'hammerjs';


import { ToastrModule } from 'ngx-toastr';
import { TourMatMenuModule } from 'ngx-tour-md-menu';

import { ExcelService } from './services/excel.service';
import { LineupComponent } from './lineup/lineup.component';
import { LolOptimizerComponent } from './lol-optimizer/lol-optimizer.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { TermsComponent } from './components/terms/terms.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CustomProjectionHelpComponent } from './lol-optimizer/custom-projection-help/custom-projection-help.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { Angulartics2Module } from 'angulartics2';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { MdToHtmlPipe } from './pipes/md-to-html.pipe';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { ContentfulService } from './services/contentful.service';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { HomePageComponent } from './components/home-page/home-page.component';



const appRoutes: Routes = [
  { path: 'lol-optimizer', component: LolOptimizerComponent },
  { path: 'articles', component: ArticleListComponent },
  { path: 'article/:id/:title', component: ArticleDetailsComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'terms', component: TermsComponent },
  { path: '', component: HomePageComponent }
];




@NgModule({
  declarations: [
    AppComponent,
    LolOptimizerComponent,
    LineupComponent,
    FooterComponent,
    HeaderComponent,
    AboutUsComponent,
    TermsComponent,
    ContactUsComponent,
    CustomProjectionHelpComponent,
    ArticleListComponent,
    MdToHtmlPipe,
    ArticleDetailsComponent,
    SlugifyPipe,
    HomePageComponent,
  ],
  entryComponents: [
    CustomProjectionHelpComponent,//should do this via routing
  ],
  imports: [
    TourMatMenuModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    RouterModule,
    FlexLayoutModule,
    BrowserModule, 
    HttpClientModule, 
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatRadioModule,
    MatInputModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSidenavModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatListModule,
    BrowserAnimationsModule,
    Angulartics2Module.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      titleClass: 'toaster-title-class',
      messageClass: 'toaster-message-class'
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  providers: [ExcelService, ContentfulService, SlugifyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
