<h1 mat-dialog-title>Custom Projections</h1>
<div mat-dialog-content>
  <p>Custom Projections allow you to assign your own numeric score to player pools to then be used by the optimizer. 
    The default valuation used by the optimizer to create lineups is the Draft Kings Average Points Per Game. 
    Statistically, this performs rather poorly in predicitng the highest score for the following match-ups. 
    For fantasy sports players that prefer to use their own valuation when they build lineups, 
    whether that be a different statistic, a blend of stats, or a custom projection algorithm, the Custom Projection feature will allow for this. 
    You can enter in any sort of valuation you want, provided it is a number. (i.e. You cannot put in N/A or any non-number values.)
  </p>
    <ul>
      <li><b>Use Custom FPPG Values</b> - This activates the Custom Projection mode. In addition to switching out the Draft Kings Average Points column, it tells the optimizer to use your custom player valuation instead.</li>
      <BR>
      <li><b>Exclude 0 FPPG Players</b> - Draft Kings will often include players in the player pool that will not play for the upcoming week or players who have very limited data. If you wish to skip entering a projection for these players, simply leave their projection or value at 0. Then, toggle this setting ON so the optimizer doesn't include these players. You will see the Exclude button activated when you turn this feature on.</li>
      <BR>
      <li><b>Export Custom Projection Template</b> - Some player pools can have well over 100 players so entering in custom valuations or projections for each player would be quite cumbersome. Instead, you can export a Custom Projection Template for that player pool and paste in the values using Excel or something similar. Please take care not to edit the structure of the sheet or change the format from CSV. If you're using Excel you can reorder the columns to make it easier to paste in values en masse. Just make sure that you save the file as a CSV. Adding non-CSV functionality like a sortable column requires you to go the "Save As" to get your data to stay in the sheet.</li>
      <BR>
      <li><b>Reset Custom Projection</b> - This control has two purposes. First, it will of course blow away all your custom projections and return every player's value to zero. In addition, if you would like to upload a custom projection sheet again, please click this button first. Attempting to overwrite the custom template you've already uploaded will not work.</li>
    </ul>  
</div>
<div mat-dialog-actions class="close-button">
  <button mat-button (click)="onNoClick()">Close</button>
</div>