import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentfulService } from '../../services/contentful.service';
import { Observable } from 'rxjs';
import { SlugifyPipe } from '../../pipes/slugify.pipe';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {

  articles$: Observable<any>;

  constructor(
    private router: Router,
    private contentfulService: ContentfulService,
    private slugifyPipe: SlugifyPipe
  ) { }

  ngOnInit() {
    this.articles$ = this.contentfulService.getArticles();
    // this.contentfulService.logArticles();
  }

  goToArticleDetailsPage(articleId, title) {
    this.router.navigate(['/article', articleId, this.slugifyPipe.transform(title)]);
  }

}
