<!-- <table>
<td>
  <tr><button mat-flat-button (click)="clearLineup()" >Clear Lineup</button></tr>
</td>
</table> -->
<table mat-table [dataSource]="lineupSource" matSort >

    <ng-container matColumnDef="player">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Player</th>
        <td mat-cell *matCellDef="let player"> {{player.player}} </td>
      </ng-container>

    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
        <td mat-cell *matCellDef="let player"> {{player.team}} </td>
      </ng-container>

    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
        <td mat-cell *matCellDef="let player"> {{player.position}} </td>
      </ng-container>

    <ng-container matColumnDef="salary">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Salary</th>
        <td mat-cell *matCellDef="let player"> {{player.salary}} </td>
    </ng-container>

    <ng-container matColumnDef="fixture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fixture</th>
      <td mat-cell *matCellDef="let player"> {{player.fixture}} </td>
    </ng-container>

    <ng-container matColumnDef="fppg">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FPPG</th>
      <td mat-cell *matCellDef="let player"> {{player.fppg}} </td>
    </ng-container>

    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Remove</th>
      <td mat-cell *matCellDef="let player"> <mat-icon (click)="removePlayer(player)" svgIcon="x-button"></mat-icon> </td>
    </ng-container>

    <ng-container matColumnDef="customfppg">
        <th mat-header-cell mat-header-cell-customfppg *matHeaderCellDef mat-sort-header>Custom</th>
            <td mat-cell *matCellDef="let player"> {{player.customfppg}} </td>
      </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>

  </table>
