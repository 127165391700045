import { Pipe, PipeTransform } from '@angular/core';
import * as marked from 'marked';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';


@Pipe({
  name: 'mdToHtml'
})
export class MdToHtmlPipe implements PipeTransform {

  transform(value: any): any {
    let options = {
      renderNode: {
        'embedded-asset-block': (node) =>
          `<img src="${node.data.target.fields.file.url}"/>`
      }
    }
    return documentToHtmlString(value, options);
  }

}
